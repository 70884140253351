import React, { useState, useEffect } from "react"

import GiftCard from "@/components/GiftCard"

const getPaginationGifts = (gifts, pageNum, pageSize = 40) => {
  return gifts.filter((gift, index) => {
    return index >= pageNum * pageSize && index < (pageNum + 1) * pageSize
  })
}

const GiftScrollToLoad = ({ gifts }) => {
  const [loadMore, setLoadMore] = useState(false)
  const [giftStore, setCardStore] = useState([])
  const [pageNum, setPageNum] = useState(1)

  const isElementAtBottom = (scrollThreshold = 370) => {
    const scrollY = window.scrollY
    const visibleHeight = document.documentElement.clientHeight
    const pageHeight = document.documentElement.scrollHeight
    return visibleHeight + scrollY + scrollThreshold >= pageHeight
  }

  useEffect(() => {
    const initGifts = getPaginationGifts(gifts,0,40)
    setPageNum(1)
    setCardStore([...initGifts])
  },[gifts])

  useEffect(() => {
    getData(loadMore)
    setLoadMore(false)
  }, [loadMore])

  useEffect(() => {
    const onPageScroll = () => {
      isElementAtBottom() && setLoadMore(true)
    }
    window.addEventListener("scroll", onPageScroll)
    return () => {
      window.removeEventListener("scroll", onPageScroll)
    }
  }, [])

  const getData = load => {
    if (load) {
      setPageNum(pageNum + 1)
      const loadedGifts = getPaginationGifts(gifts, pageNum)
      loadedGifts.length &&
        setCardStore([...giftStore, ...loadedGifts])
    }
  }

  return (
    <>
      {giftStore.map((gift) => (
        <GiftCard gift={gift} key={gift.name.en} />
      ))}
    </>
  )
}

export default GiftScrollToLoad