import React, { useState, useContext } from "react"
import styled from "styled-components"

import useFormatLocale from "@/hooks/useFormatLocale"
import Image from "@/components/Image"
import giftBg from "../../icons/artwork.svg"

import { ZipperContext } from "@/contexts/Zipper"

const styledCard = {
  // card main color
  bg: "#fbe492",
  // card border color
  border: "#fefdf0",
  // tick highlight color
  icon: "#7a6937",
  // tick border color
  line: "#fbe492",
  // checkbox border color
  fill: "#f9f4b8",
}

const GiftCard = ({ gift }) => {
  const lng = useFormatLocale()
  const { name, icon, isCollected, palette, customize } = gift
  const { dispatch } = useContext(ZipperContext)

  const handleCheckbox = () => {
    if (isCollected) {
      dispatch({ type: "REMOVE_MARK_COLLECTED", id: gift.id })
    } else {
      dispatch({ type: "ADD_MARK_COLLECTED", id: gift.id })
    }
  }

  return (
    <StyledGiftCard bg={giftBg}>
      <div
        style={{
          borderRadius: `2px`,
          boxShadow: `0 0 0 6px ${styledCard.border}`,
          backgroundColor: `${styledCard.border}`,
        }}
      >
        <div className="gift-bg">
          <div className="gift-icon">
            <Image src={icon.childImageSharp.fluid} />
          </div>
          {!!customize && (
            <div className="gift-brush">
              <BrushMarkIcon color={styledCard} />
            </div>
          )}
          {!!palette && (
            <div className="gift-palette">
              <PaletteMarkIcon color={styledCard} />
            </div>
          )}
          {isCollected && (
            <div className="gift-storage">
              <StorageMarkIcon color={styledCard} />
            </div>
          )}
          <div
            className="gift-checkbox"
            onClick={handleCheckbox}
            style={{
              boxShadow: `0 0 0 3px ${styledCard.fill}`,
            }}
          >
            <CheckMarkIcon isMark={isCollected} color={styledCard} />
          </div>
        </div>
      </div>
      <span className="gift-title">{name[lng]}</span>
    </StyledGiftCard>
  )
}

export default GiftCard

const StyledGiftCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  @media (max-width: 450px) {
    width: calc(50vw - 20px);
  }
  .gift-bg {
    width: 124px;
    height: 184px;
    background-size: cover;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-image: url(${(props) => props.bg});
    .gift-icon {
      cursor: pointer;
      width: 72%;
    }
  }
  .gift-title {
    margin-top: 18px;
    max-width: 124px;
    text-align: center;
  }
  .gift-brush {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .gift-palette {
    position: absolute;
    top: 0px;
    left: 3px;
    width: 20px;
    height: 20px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .gift-storage {
    position: absolute;
    bottom: 6px;
    left: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .gift-checkbox {
    position: absolute;
    right: 6px;
    bottom: 6px;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    cursor: pointer;
    background-color: transparent;
    svg {
      position: absolute;
      left: -4px;
      top: -2px;
      width: 26px;
      path {
        transition: 0.3s fill;
      }
    }
  }
`

const BrushMarkIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 49 47">
      <g fill={color.icon} transform="translate(0.000000, -1.000000)">
        <g transform="translate(24.800000, 24.500000) scale(-1, 1) rotate(-180.000000) translate(-24.800000, -24.500000) translate(0.300000, 0.500000)">
          <path d="M43.1,45.9 C45.5,43.5 45,40.2 41.7,37 C40.1,35.4 38.7,33.7 38.7,33.3 C38.7,32.8 40.7,31.2 43.2,29.7 C45.7,28.2 48,26 48.3,24.9 C48.8,22.8 46.6,17.2 44.9,16.1 C44.3,15.7 15.5,31.3 14,32.9 C13.8,33.1 14.7,34.7 15.9,36.5 C19.2,41.3 21.6,41.8 27.1,38.9 C32.2,36.2 33.7,36.4 33.7,39.9 C33.7,42.9 37.3,47.5 39.7,47.5 C40.7,47.5 42.3,46.8 43.1,45.9 Z M28.2,21.3 C36.2,16.7 42.6,12.6 42.6,12 C42.4,9.5 36.8,1.9 34.5,1.1 C32.3,0.3 30.3,1.1 21.1,6.3 C15.1,9.7 10.8,12.5 11.5,12.5 C13.1,12.5 17.7,17 17.7,18.6 C17.7,19.3 16.6,18.6 15.2,17.1 C12.2,13.9 7.8,13.7 3.1,16.6 C-0.1,18.5 -0.8,21 2,19.9 C3.8,19.3 8.4,23 10.2,26.6 C11.1,28.2 12.2,29.5 12.7,29.5 C13.3,29.5 20.2,25.8 28.2,21.3 Z M28.2,21.3 C36.2,16.7 42.6,12.6 42.6,12 C42.4,9.5 36.8,1.9 34.5,1.1 C32.3,0.3 30.3,1.1 21.1,6.3 C15.1,9.7 10.8,12.5 11.5,12.5 C13.1,12.5 17.7,17 17.7,18.6 C17.7,19.3 16.6,18.6 15.2,17.1 C12.2,13.9 7.8,13.7 3.1,16.6 C-0.1,18.5 -0.8,21 2,19.9 C3.8,19.3 8.4,23 10.2,26.6 C11.1,28.2 12.2,29.5 12.7,29.5 C13.3,29.5 20.2,25.8 28.2,21.3 Z"></path>
        </g>
      </g>
    </svg>
  )
}

const PaletteMarkIcon = ({ color }) => {
  return (
    <svg viewBox="0 0 75 59">
      <g fill={color.icon} transform="translate(-1.000000, -1.000000)">
        <g transform="translate(38.442674, 30.361136) scale(-1, 1) rotate(-180.000000) translate(-38.442674, -30.361136) translate(0.942674, 0.861136)">
          <path d="M29,58.3 C16.4,55.9 6.7,49.6 2.5,40.8 C0.1,36 -0.2,34.4 0.2,28.5 C1.5,11 14.6,0 34,0 C47.6,0 59.8,5.1 67.9,13.9 C75.2,21.8 76.1,28.4 70.6,33.9 C66.6,37.9 65.3,38 57.4,35.3 C54.2,34.2 51,33.5 50.3,33.7 C47.7,34.7 49.1,38.1 54,42.2 C59.5,46.9 60.1,48.9 56.7,52.1 C51.2,57.3 38.6,60.1 29,58.3 Z M41.7,46.8 C44.6,43.5 43.6,40.5 38.9,38 C35,35.9 29.7,36.3 26.9,38.9 C23.5,42 24.7,46.8 29.4,48.9 C33.7,50.8 39,49.9 41.7,46.8 Z M59.4,18.7 C61.7,16.4 61.4,14.4 58.5,13.3 C57.1,12.8 56,11.9 56,11.3 C56,9.9 52.7,10 51.2,11.5 C49.7,13 49.7,15.8 51,18.4 C52.3,20.7 57.2,21 59.4,18.7 Z M31.8,16.7 C33,15.9 34,14.4 34,13.4 C34,10.9 28.4,7 26,7.8 C22.1,9 24.2,18.3 28.3,18.3 C29,18.3 30.6,17.6 31.8,16.7 Z M47.2,14 C48.1,12.2 47.8,11.6 44.9,9.1 C43,7.6 40.9,6.4 40.1,6.6 C37.9,6.9 36.6,9.8 37.4,12.7 C38.5,17.3 44.9,18.2 47.2,14 Z"></path>
        </g>
      </g>
    </svg>
  )
}

const StorageMarkIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 45 45">
      <g transform="translate(-1.000000, -1.000000)">
        <g transform="translate(23.880366, 23.027172) scale(-1, 1) rotate(-180.000000) translate(-23.880366, -23.027172) translate(0.880366, 0.527172)">
          <path
            fill={color.icon}
            d="M34.4,40 C39.5,37.7 43.9,35.3 44.3,34.7 C44.7,34.2 45.1,28.5 45.1,22.2 C45.1,15.9 44.7,10.2 44.3,9.7 C43.1,7.9 25.4,0.2 22.6,0.2 C19.8,0.2 2.1,7.9 0.9,9.7 C-0.1,11 -0.1,33.4 0.9,34.7 C2.2,36.5 19.5,44.1 22.4,44.2 C24,44.2 29.4,42.3 34.4,40 Z"
          ></path>
          <path
            fill={color.fill}
            d="M17.6,39.2 C15.4,38.2 11.4,36.4 8.6,35.3 C2.9,32.8 2.7,33 14.7,27.7 L22.7,24.1 L31.9,28.3 C37,30.6 40.8,32.8 40.4,33.2 C39.4,34.2 23.2,41.2 22.3,41.1 C21.9,41.1 19.8,40.2 17.6,39.2 Z"
          ></path>
          <path
            fill={color.fill}
            d="M6.1,23.8 C6.1,22.6 10.8,20.2 13.2,20.2 C15.5,20.2 13.7,23.1 11,24.1 C6.8,25.5 6.1,25.5 6.1,23.8 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const CheckMarkIcon = ({ isMark, color }) => {
  const mainColor = isMark ? color.icon : color.fill
  const lineColor = isMark ? color.fill : color.bg
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 59 47">
      <g transform="translate(0.000000, -1.000000)">
        <g transform="translate(29.500000, 24.180919) scale(-1, 1) rotate(-180.000000) translate(-29.500000, -24.180919) translate(0.000000, 0.680919)">
          <path
            fill={lineColor}
            d="M47,46.3 C46.2,46 40.4,41.4 34.2,36.2 L22.9,26.7 L19.7,29.8 C16.1,33.3 10.8,34.1 7.7,31.8 C4.5,29.3 0,22.7 0,20.4 C0,15.8 16.9,0 21.8,0 C24.7,0 30.1,5.2 48.8,25.8 C58.7,36.7 59.8,39.6 56.3,43.9 C54.4,46.1 49.6,47.4 47,46.3 Z"
          ></path>
          <path
            fill={mainColor}
            d="M52,40.5 C52.6,39.7 52.8,38.5 52.4,37.8 C50.7,35 22.7,5 21.8,5 C20.3,5 5,19 5,20.3 C5,22.1 11.2,28 13.1,28 C14.1,28 16.3,26.2 18,24 C19.7,21.8 21.6,20 22.1,20 C22.7,20 28.9,24.9 36.1,31 C43.2,37.1 49.5,42 49.9,42 C50.4,42 51.3,41.3 52,40.5 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}