import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import GiftScrollToLoad from "@/components/GiftScrollToLoad"
import { EventTabs, TextTab } from "@/components/Tabs"
import NoMatch from "@/components/NoMatch"

import useLocale from "@/hooks/useLocale"
import ZipperContextProvider, { ZipperContext } from "@/contexts/Zipper"
import useTranslation from "@/hooks/useTranslation"
import createNodeId from "@/utils/createNodeId"
import { SEASON_EVENTS } from "@/utils/constants"

const ZipperGiftPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allZipperYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          gifts {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            event
            palette
            customize
          }
        }
      }
    }
  `)
  const t = useTranslation()
  const lng = useLocale()
  const { gifts, ...ZipperInfo } = queryData.allZipperYaml.nodes[0]

  const giftsHasMarked = gifts.map((gift) => ({
    ...gift,
    isCollected: false,
    id: createNodeId(gift.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  return (
    <Layout>
      <PageContainer>
        <div className="header">
          <Image
            className="img"
            src={ZipperInfo.avatar.childImageSharp.fluid}
          />
          <span>{t(ZipperInfo.name)}</span>
        </div>
        <EventTabs lng={lng} value={value} onChange={handleChange}>
          <TextTab label="all" />
          {Object.values(SEASON_EVENTS).map((event) => (
            <TextTab text={event[lng]} key={event.en} />
          ))}
        </EventTabs>
        <EventTabs lng={lng} value={value2} onChange={handleChange2}>
          <TextTab label="all" />
          <TextTab label="notCollected" />
          <TextTab label="collected" />
        </EventTabs>
        <ZipperContextProvider>
          <GiftCards gifts={giftsHasMarked} tabs={{ value, value2 }} />
        </ZipperContextProvider>
      </PageContainer>
    </Layout>
  )
}

export default ZipperGiftPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img {
      width: 150px;
      border-radius: 50%;
    }
    span {
      margin: 14px 0 18px;
    }
  }
`

const GiftCards = ({ gifts, tabs }) => {
  const { zipper } = useContext(ZipperContext)
  const currentGifts = React.useMemo(() => {
    const { value,value2 } = tabs

    const giftsHasMarked = gifts.map((gift) => {
      const giftHasCollected = zipper.filter((id) => id === gift.id)
      return {
        ...gift,
        isCollected: giftHasCollected.length ? true : false,
      }
    })

    let giftsArr = [...giftsHasMarked]

    if (value) {
      giftsArr = giftsArr.filter(
        (gift) => gift.event === value
      )
    }

    if (value2 === 1) {
      giftsArr = giftsArr.filter((gift) => !gift.isCollected)
    } else if (value2 === 2) {
      giftsArr = giftsArr.filter((gift) => gift.isCollected)
    }

    return giftsArr
  }, [zipper, gifts, tabs])
  return (
    <StyledGiftCards>
      {currentGifts.length ? (
        <div className="gift_cards">
          <GiftScrollToLoad gifts={currentGifts} />
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledGiftCards>
  )
}

const StyledGiftCards = styled.div`
  .gift_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`
