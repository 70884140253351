import React, { createContext,useState, useReducer, useEffect } from 'react';
import { ZipperReducer } from './reducer';

export const ZipperContext = createContext();

const ZipperContextProvider = ({children}) => {
  const [hasInit, setHasInit] = useState(false);
  const [zipper, dispatch] = useReducer(ZipperReducer, []);
  useEffect(()=>{
    const localData = localStorage.getItem('zipper');
    const localZipper = localData ? JSON.parse(localData) : [];
    if(Array.isArray(localZipper) && localZipper.length){
      dispatch({
        type: 'UPDATE_LOCAL_DATA',
        value: localZipper
      })
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('zipper', JSON.stringify(zipper));
    }
  }, [zipper]);
  return (
    <ZipperContext.Provider value={{ zipper, dispatch }}>
      {children}
    </ZipperContext.Provider>
  );
}
 
export default ZipperContextProvider;